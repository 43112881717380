export default {
  referenceVar: [{
    referenceName: '{{customer.username}}',
    referenceCryptic: '用户昵称',
    referenceTag: 'customer'
  }, {
    referenceName: '{{customer.email}}',
    referenceCryptic: '用户邮箱',
    referenceTag: 'customer'
  }, {
    referenceName: '{{asset.uid}}',
    referenceCryptic: '内部ID',
    referenceTag: 'asset'
  }, {
    referenceName: '{{asset.name}}',
    referenceCryptic: '资产名称',
    referenceTag: 'asset'
  }]

}