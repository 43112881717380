<template>
  <div class="reference-variable">
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleReference"
      width="563px"
      class="pop-dialog"
      :modal-append-to-body="false"
      title="引用变量"
    >
      <div>
        <el-table
          :data="variableData"
          style="width:487px;"
          header-cell-class-name="table-head"
          cell-class-name="table-cell"
          @selection-change="handleSelectionChange"
          ref="multipleTable">
          <el-table-column type="selection" align="left" width="100" ></el-table-column>
          <el-table-column
            prop="referenceName"
            label="变量"
            align="left"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="referenceCryptic"
            label="含义"
            align="left"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="sure">确 定</el-button>
        <el-button @click="dialogVisibleReference = false" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import referenceData from "./variable";
export default {
  data() {
    return {
      variableData: [],
      dialogVisibleReference: false,
      selectData: [],
      type: "",
    };
  },
  methods: {
    init() {
      this.type = this.$route.name;
      const data = referenceData.referenceVar;
      if (this.type == "messageInfo" || this.type == "messageImgInfo") {
        this.variableData = data.filter((e) => {
          return e.referenceTag == "customer";
        });
      } else {
        this.selectData = [];
        this.variableData = data;
      }
    },
    show() {
      this.$forceUpdate();
      this.init();
      this.dialogVisibleReference = true;
      if (this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
        this.selectData = [];
      }
    },
    clear() {},
    handleSelectionChange(data) {
      this.selectData = data;
    },
    sure() {
      if (this.selectData.length === 0) {
        this.$$warning("请选择变量");
        return;
      }
      let selectDataString = '';
      this.selectData.forEach(element => {
        selectDataString += element.referenceName;
      });
      if (this.type == 'messageInfo' || this.type == 'messageImgInfo') {
        this.$emit("insert", selectDataString);
        this.selectData = [];
      } else {
        this.$emit("insert", selectDataString);
        this.$refs.multipleTable.clearSelection();
        this.selectData = [];
      }
      this.dialogVisibleReference = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
