<template>
  <div class="systematic-notification" v-if="hasResource('system_message_management')">
    <div class="search-form">
      <div class="dis-fix">
        <el-form :inline="true">
          <el-form-item label="通知编号">
            <el-input v-model="messageId" placeholder="输入编号" clearable />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="status" placeholder="请选择">
              <el-option label="全部" value></el-option>
              <el-option label="未生效" value="0"></el-option>
              <el-option label="已生效" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="text-btn">
          <span class="serach-button" @click="handleQuery" v-loading="isQueryLoading">查询</span>
          <span class="clear-button" @click="handleReset">清空</span>
        </div>
      </div>
    </div>
    <el-table
      :data="messageData"
      style="width: 100%"
      class="data-table"
      header-cell-class-name="table-head"
      cell-class-name="table-cell"
      v-loading="isTableLoading">
      <el-table-column
        prop="id"
        label="通知编号"
        align="left"
        :show-overflow-tooltip="true"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="type"
        label="事件名称"
        align="left"
        width="160px"
        :show-overflow-tooltip="true" >
        <template slot-scope="scope">
          <span v-if="scope.row.type === 11">资产下架通知</span>
          <span v-if="scope.row.type === 12">资产重新上架通知</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="通知标题"
        align="left"
        width="280px"
        :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-input
            id="messageTitle"
            class="message-title"
            v-if="inputShow && inputId === scope.row.id"
            v-model="inputShowTitle"
            placeholder="必填，50字符以内"
             @focus="setContentFocus = false"
            @blur="setContentFocus = false"
          ></el-input>
          <span v-else>{{scope.row.title || '空'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="content"
        label="通知内容"
        align="left"
        width="400px"
        :show-overflow-tooltip="true"
        empty-text="空">
        <template slot-scope="scope">
          <el-input
            id="messageContent"
            v-if="inputShow && inputId === scope.row.id"
            v-model="inputShowContent"
            placeholder="必填，内容在200字符以内"
            class="message-content"
            @focus="setContentFocus = true"
          ></el-input>
          <span v-else>{{scope.row.content || '空'}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="left"
        width="100"
        :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 0">未生效</span>
          <span v-if="scope.row.status === 1">已生效</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="left"
        fixed="right"
        class-name="small-padding fixed-width"
        min-width="250px;">
        <template slot-scope="scope">
          <div class="icon-btn">
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="inputShow &&inputId === scope.row.id"
              @click="handleReference(scope.row)"
            >
              <i class="iconfont">&#xe675;</i>引用变量
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="inputShow && inputId === scope.row.id"
              @click="handleSave(scope.row)"
            >
              <i class="iconfont">&#xe607;</i>保存
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="inputShow && inputId === scope.row.id"
              @click="handleNoSave(scope.row)"
            >
              <i class="iconfont">&#xe678;</i>取消
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              @click="messageEdit(scope.row)"
              v-if="(!inputShow || inputId !== scope.row.id) && hasResource('system_message_update')"
            >
              <i class="iconfont">&#xe677;</i>编辑
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="(scope.row.status == 0&&hasResource('system_message_enable')) && (!inputShow || inputId !== scope.row.id)"
              @click="messageEnable(scope.row)"
            >
              <i class="iconfont">&#xe674;</i>生效
            </el-button>
            <el-button
              class="preview-color"
              size="mini"
              type="text"
              v-if="(scope.row.status == 1&&hasResource('system_message_disable')) && (!inputShow || inputId !== scope.row.id)"
              @click="messageDisable(scope.row)"
            >
              <i class="iconfont">&#xe64b;</i>停用
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleEdit"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <div>
        <span class="fz-18 line-height-36">
          您将重新设置事件
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="notificationName === 11">资产下架通知</span>
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="notificationName === 12">资产重新上架通知</span>的内容
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="editSure">确 定</el-button>
        <el-button @click="dialogVisibleEdit = false" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 生效 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleEnable"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <div>
        <span class="fz-18 line-height-36">
          您将生效事件
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="enableName === 11">资产下架通知</span>
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="enableName === 12">资产重新上架通知</span>的内容
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="enableSure">确 定</el-button>
        <el-button @click="dialogVisibleEnable = false" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 停用 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleDisable"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <div>
        <span class="fz-18 line-height-36">
          您将停用事件
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="disableName === 11">资产下架通知</span>
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="disableName === 12">资产重新上架通知</span>的内容
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="disableSure">确 定</el-button>
        <el-button @click="dialogVisibleDisable = false" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 保存 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleSave"
      width="458px"
      class="pop-dialog"
      :modal-append-to-body="false"
    >
      <div>
        <span class="fz-18 line-height-36">
          您是否保存事件
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="saveType === 11">资产下架通知</span>
          <span class="fz-24 bold mg-l-10 mg-r-10" v-if="saveType === 12">资产重新上架通知</span>的内容
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="confirm-button" @click="saveSure">确 定</el-button>
        <el-button @click="noSave" class="cancel-button">取 消</el-button>
      </span>
    </el-dialog>

    <reference-variable ref="reference" @insert="handleInsert"></reference-variable>

    <div class="ta-r mg-t-18">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10,20,50]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import api from "@/api/notification.js";
import referenceVariable from "@/components/reference-variable/index.vue";
export default {
  components: { referenceVariable },
  data() {
    return {
      messageData: [],
      username: "",
      status: "",
      isQueryLoading: false,
      pagesize: 10,
      currentPage: 1,
      total: 0,
      messageId: "",
      isTableLoading: false,
      dialogVisibleEdit: false,
      notificationName: "",
      dialogVisibleEnable: false,
      dialogVisibleDisable: false,
      dialogVisibleSave: false,
      enableName: "",
      disableName: "",
      saveName: "",
      enableId: "",
      disableId: "",
      inputShow: false,
      inputShowTitle: "",
      inputShowContent: "",
      inputId: "",
      setContentFocus: false,
      saveType: "",
    };
  },
  methods: {
    init() {
      this.getMessageList();
    },
    handleSizeChange(val) {
      this.pagesize = val;
      this.getMessageList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMessageList();
    },
    getMessageList() {
      this.isTableLoading = true;
      let data = {
        limit: this.pagesize,
        page: this.currentPage,
        filter: {
          id: this.messageId,
          status: this.status,
        },
      };
      api.notificationList(data).then((res) => {
        if (res.data.code === 10200) {
          this.messageData = res.data.data.items;
          this.total = res.data.data.total;
        }
        this.isQueryLoading = false;
        this.isTableLoading = false;
      });
    },
    // 查询
    handleQuery() {
      this.isQueryLoading = true;
      this.getMessageList();
    },
    handleReset() {
      this.status = "";
      this.messageId = "";
    },
    messageEdit(row) {
      this.saveName = row.type;
      if (this.inputShow === true) {
        this.dialogVisibleSave = true;
      } else {
        this.dialogVisibleEdit = true;
        this.notificationName = row.type;
        this.inputId = row.id;
        this.inputShowTitle = row.title;
        this.inputShowContent = row.content;
      }
    },
    editSure() {
      this.dialogVisibleEdit = false;
      this.inputShow = true;
      this.saveType = this.saveName;
    },
    // 生效
    messageEnable(row) {
      this.saveName = row.type;
      if (this.inputShow === true) {
        this.dialogVisibleSave = true;
      } else {
        this.dialogVisibleEnable = true;
        this.enableName = row.type;
        this.enableId = row.id;
      }
    },

    enableSure() {
      let data = {
        id: this.enableId,
      };
      api.notificationEnable(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success(res.data.message);
          this.dialogVisibleEnable = false;
          this.getMessageList();
        } else {
          this.$$error(res.data.message);
          this.dialogVisibleEnable = false;
        }
      });
    },
    // 停用
    messageDisable(row) {
      this.saveName = row.type;
      if (this.inputShow === true) {
        this.dialogVisibleSave = true;
      } else {
        this.dialogVisibleDisable = true;
        this.disableName = row.type;
        this.disableId = row.id;
      }
    },

    disableSure() {
      let data = {
        id: this.disableId,
      };
      api.notificationDisable(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success(res.data.message);
          this.dialogVisibleDisable = false;
          this.getMessageList();
        } else {
          this.$$error(res.data.message);
          this.dialogVisibleDisable = false;
        }
      });
    },

    // 保存
    handleSave(row) {
      this.saveName = row.type;
      // this.dialogVisibleSave = true;
      this.saveSure();
    },
    saveSure() {
      var title = document.getElementById("messageContent");
      let data = {
        id: this.inputId,
        content: title.value,
        title: this.inputShowTitle,
      };
      if (this.inputShowTitle === "") {
        this.$$warning("通知标题不能为空");
        this.dialogVisibleSave = false;
        return;
      }
      if (this.inputShowTitle.length > 50) {
        this.$$warning("标题长度不能超过50个字符！");
        this.dialogVisibleSave = false;
        return;
      }
      if (title.value.length > 200) {
        this.$$warning("内容长度不能超过200个字符！");
        this.dialogVisibleSave = false;
        return;
      }
      api.notificationSave(data).then((res) => {
        if (res.data.code === 10200) {
          this.dialogVisibleSave = false;
          this.$$success(res.data.message);
          this.inputShow = false;
          this.getMessageList();
        } else {
          this.$$error(res.data.message);
          this.dialogVisibleSave = false;
        }
      });
    },
    noSave() {
      this.dialogVisibleSave = false;
      this.inputShow = false;
    },
    handleNoSave() {
      this.inputShow = false;
    },
    // 引用变量
    handleReference(row) {
      if (!this.setContentFocus) return;
      this.$nextTick(() => {
        this.$refs.reference.show();
      });
    },
    handleInsert(data) {
      let reference = data;
      var title = document.getElementById("messageContent");
      var startPos = title.selectionStart;
      var endPos = title.selectionEnd;
      if (startPos === undefined || endPos === undefined) return;
      var txt = title.value;
      var result =
        txt.substring(0, startPos) + reference + txt.substring(endPos);
      title.value = result;
      title.focus();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less">
.message-title {
  width: 153px;
  input {
    background-color: #ededed;
  }
}
.message-content {
  width: 353px;
  input {
    background-color: #ededed;
  }
}
</style>