import axios from '../uitls/require'

export default {
  notificationList(data) {
    return axios.post('/admin/system-message/list', data)
  },
  notificationEnable(data) {
    return axios.post('/admin/system-message/enable', data)
  },
  notificationDisable(data) {
    return axios.post('/admin/system-message/disable', data)
  },
  notificationSave(data) {
    return axios.post('/admin/system-message/update', data)
  }
}